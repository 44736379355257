.row {
  width: 100%;
  height: 100%;
  border: 2px solid rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* border-radius: 10px; */
}

.rowResolved {
  width: 100%;
  height: 100%;
  border: 2px solid rgb(0, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  /* border-radius: 10px; */
}

.mainPane {
  width: 29%;
  height: 100%;
  background: rgb(91, 189, 235);
  display: inline-flex;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*border-radius: 0px 10px 10px 0px;*/
}

.mainPaneResolved {
  width: 29%;
  height: 100%;
  background: rgb(194, 245, 194);
  display: inline-flex;
}

.descriptionPane {
  width: 59%;
  height: 100%;
  background: rgb(91, 189, 235);
  font-size: 15px;
  justify-content: left;
  align-items: center;
}

.descriptionPaneResolved {
  width: 59%;
  height: 100%;
  background: rgb(194, 245, 194);
  font-size: 15px;
  justify-content: left;
  align-items: center;
}

.dividerPane {
  width: 1%;
  height: 100%;
  background: rgb(91, 189, 235);
  font-size: 15px;
  justify-content: left;
  align-items: center;
}

.dividerPaneResolved {
  width: 1%;
  height: 100%;
  background: rgb(194, 245, 194);
  font-size: 15px;
  justify-content: left;
  align-items: center;
}

.paneRequired {
  width: 5%;
  height: 100%;
  color: white;
  background: blue;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 10px 0px 0px 10px;*/
}

.paneOptional {
  width: 5%;
  height: 100%;
  color: white;
  background: rgb(67, 194, 211);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 10px 0px 0px 10px;*/
}

.paneStatusMissing {
  width: 5%;
  height: 100%;
  color: rgb(255, 255, 255);
  background: rgb(226, 22, 22);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.paneStatusSubmitted {
  width: 5%;
  height: 100%;
  color: rgb(255, 255, 255);
  background: rgb(13, 205, 253);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.paneStatusComment {
  width: 5%;
  height: 100%;
  color: black;
  background: rgb(201, 219, 35);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.paneStatusApproved {
  width: 5%;
  height: 100%;
  color: rgb(255, 255, 255);
  background: rgb(56, 199, 20);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.h-text {
  position: absolute;
  transform: rotate(-90deg);
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
}
