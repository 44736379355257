@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Regular.eot");
  src: url("assets/fonts/vazir/Vazir-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Regular.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Regular.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Bold.eot");
  src: url("assets/fonts/vazir/Vazir-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Bold.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Bold.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Black.eot");
  src: url("assets/fonts/vazir/Vazir-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Black.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Black.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Medium.eot");
  src: url("assets/fonts/vazir/Vazir-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Medium.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Medium.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Light.eot");
  src: url("assets/fonts/vazir/Vazir-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Light.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Light.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url("assets/fonts/vazir/Vazir-Thin.eot");
  src: url("assets/fonts/vazir/Vazir-Thin.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/vazir/Vazir-Thin.woff2") format("woff2"),
    url("assets/fonts/vazir/Vazir-Thin.woff") format("woff"),
    url("assets/fonts/vazir/Vazir-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
